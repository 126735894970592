import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Text, Box, Link, Flex } from 'rebass';
import Fade from 'react-reveal/Fade';
import SocialLink from '../SocialLink';

const FooterContainer = styled.div`
  min-width: 320px;
  max-width: 1366px;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`;

const TextFooter = styled(Text)`
  color: ${props => props.theme.colors.background};

  & a {
    color: ${props => props.theme.colors.background};
  }
`;

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        contentfulAbout {
          name
          roles
        }
        allContentfulSocialLink {
          edges {
            node {
              id
              url
              name
              fontAwesomeIcon
            }
          }
        }
      }
    `}
    render={({ contentfulAbout, allContentfulSocialLink }) => {
      const { name } = contentfulAbout;
      const { edges: socialLinks } = allContentfulSocialLink;

      return (
        <Box p={3} backgroundColor="primaryDark" as="footer">
          <FooterContainer>
            <Fade left>
              <TextFooter fontSize={[2, 3]}>
                <span>{`${name} Portfolio - Powered by `}</span>
                <Link href="https://www.gatsbyjs.org/">Gatsby</Link>
                <span> and </span>
                <Link href="https://www.contentful.com/" mr={1}>
                  Contentful
                </Link>
                <span role="img" aria-label="heart">
                  ❤️
                </span>
              </TextFooter>
            </Fade>
            <Flex>
              <Fade right>
                {socialLinks.map(({ node }) => {
                  const { id } = { ...node };
                  return (
                    <Box mx={[2, 3]} fontSize={[4, 5]} key={id}>
                      <SocialLink {...node} color="background" />
                    </Box>
                  );
                })}
              </Fade>
            </Flex>
          </FooterContainer>
        </Box>
      );
    }}
  />
);

export default Footer;
